<template>
    <div class="nav-left-panel">
        <ts-search :param="{}"></ts-search>

        <div class="nav-left-panel__list" v-show="hotList.length > 0">
            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">热门推荐</p>
                <div>
                    <div class="hot-recommend" v-for="(item, i) in hotList" :key="item.id" @click="$help.openUrl(item)">
                        {{item.viewName}}
                        <i>0{{i + 1}}</i>
                        <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                    </div>
                </div>
            </div>

            <div class="nav-left-panel__item" v-show="newList.length > 0">
                <p class="nav-left-panel__title">新品发布</p>
                <p class="nav-left-panel__desc" v-for="item in newList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item" v-show="productList.length > 0">
                <p class="nav-left-panel__title">产品动态</p>
                <p class="nav-left-panel__desc" v-for="item in productList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item" v-show="otherList.length > 0">
                <p class="nav-left-panel__title">其他</p>
                <p class="nav-left-panel__desc" v-for="item in otherList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                visitId: new Date().getTime(),
                pageId: 'navProduct',
                pageName: '导航-热门产品',

                searchTxt: '',

                hotList: [],
                newList: [],
                productList: [],
                otherList: [],
            }
        },
        created() {
            // 获取热门推荐
            this.$help.getUrlIndex(2, 8, undefined, this.visitId)
                .then(res => {
                    this.hotList = res.data.records
                })

            this.$help.getUrlIndex(3, 20, undefined, this.visitId)
                .then(res => {
                    this.newList = res.data.records
                })

            this.$help.getUrlIndex(4, 20, undefined, this.visitId)
                .then(res => {
                    this.productList = res.data.records
                })

            this.$help.getUrlIndex(5, 20, undefined, this.visitId)
                .then(res => {
                    this.otherList = res.data.records
                })
            this.$help.socket.send(this)
        },
        methods: {
            querySearch(queryString, cb) {
                this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {keyword: queryString}).then(res => {
                    cb(res.data);
                });
            },
            search() {
                if(this.searchTxt) {
                    this.$router.push({
                        path: `/drug/${this.$help.encryption(this.searchTxt)}`,
                    })
                }
            },
        }
    }
</script>